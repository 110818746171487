import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router'
import moment from 'moment-timezone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowDownAZ,
  faArrowUpAZ,
  faClose,
  faPenToSquare,
  faSearch,
  faSortNumericDown,
  faSortNumericUp,
} from '@fortawesome/free-solid-svg-icons'
import { useLazyGetAllEngagementsQuery } from '../../redux/engagement.endpoints'
import { Engagement } from '../../types/Engagement'

interface Props {
  companyId: number
}

const EngagementsView = ({ companyId }: Props) => {
  const [sortColumn, setSortColumn] = useState('projectName')
  const [sortOrder, setSortOrder] = useState('asc')
  const navigate = useNavigate()
  const [engagements, setEngagements] = useState([] as Engagement[])
  const [searchQuery, setSearchQuery] = useState('')
  const [isSearch, setIsSearch] = useState(false)
  const [sortedEngagements, setSortedEngagements] = useState([] as Engagement[])
  const [getEngagements, { data }] = useLazyGetAllEngagementsQuery()

  const handleSort = (column: string) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortColumn(column)
      setSortOrder('asc')
    }
    const sortedEngagements = [...engagements].sort((a, b) => {
      if (column === 'projectName') {
        return sortOrder === 'asc'
          ? a.projectName.localeCompare(b.projectName)
          : b.projectName.localeCompare(a.projectName)
      } else if (column === 'client.name') {
        return sortOrder === 'asc'
          ? a.client.name.localeCompare(b.client.name)
          : b.client.name.localeCompare(a.client.name)
      } else if (column === 'team.name') {
        return sortOrder === 'asc'
          ? a.team.name.localeCompare(b.team.name)
          : b.team.name.localeCompare(a.team.name)
      } else if (column === 'startDate') {
        return sortOrder === 'asc'
          ? moment(a.startDate).unix() - moment(b.startDate).unix()
          : moment(b.startDate).unix() - moment(a.startDate).unix()
      } else if (column === 'endDate') {
        return sortOrder === 'asc'
          ? moment(a.endDate).unix() - moment(b.endDate).unix()
          : moment(b.endDate).unix() - moment(a.endDate).unix()
      }
      return 0
    })
    setSortedEngagements(sortedEngagements)
  }

  useEffect(() => {
    setSortedEngagements(
      [...engagements].sort((a, b) => {
        if (sortColumn === 'projectName') {
          return sortOrder === 'asc'
            ? a.projectName.localeCompare(b.projectName)
            : b.projectName.localeCompare(a.projectName)
        } else if (sortColumn === 'client.name') {
          return sortOrder === 'asc'
            ? a.client.name.localeCompare(b.client.name)
            : b.client.name.localeCompare(a.client.name)
        } else if (sortColumn === 'team.name') {
          return sortOrder === 'asc'
            ? a.team.name.localeCompare(b.team.name)
            : b.team.name.localeCompare(a.team.name)
        } else if (sortColumn === 'startDate') {
          return sortOrder === 'asc'
            ? moment(a.startDate).unix() - moment(b.startDate).unix()
            : moment(b.startDate).unix() - moment(a.startDate).unix()
        } else if (sortColumn === 'endDate') {
          return sortOrder === 'asc'
            ? moment(a.endDate).unix() - moment(b.endDate).unix()
            : moment(b.endDate).unix() - moment(a.endDate).unix()
        }
        return 0
      }),
    )
  }, [engagements, sortColumn, sortOrder])

  useEffect(() => {
    setEngagements(data || [])
  }, [data])

  useEffect(() => {
    getEngagements({
      companyId,
      pageSize: 0,
      pageNumber: 0,
      sortBy: 'id',
      searchQuery: '',
      sortType: 'DESC',
    })
  }, [])

  useEffect(() => {
    if (data) {
      setEngagements(data)
      setSortedEngagements(
        [...data].sort((a, b) => a.projectName.localeCompare(b.projectName)),
      )
    }
  }, [data])

  const clearSearch = () => {
    getEngagements({
      companyId,
      pageSize: 0,
      pageNumber: 0,
      sortBy: 'id',
      searchQuery: '',
      sortType: 'DESC',
    })
    setSearchQuery('')
    setIsSearch(false)
  }

  const handleSearch = () => {
    if (searchQuery) {
      getEngagements({
        companyId,
        pageSize: 0,
        pageNumber: 0,
        sortBy: 'id',
        searchQuery,
        sortType: 'DESC',
      })
      setIsSearch(true)
    }
  }

  const handleEnterKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  return (
    <div className="container my-4">
      <Helmet title="Time Off - Engagements" />
      <p className="fs-2 fw-bold">Manage Engagements</p>
      <div className="d-flex justify-content-between py-2 bottom-divider">
        <p className="h3 mb-0">Filters</p>
        <button
          className="btn btn-primary"
          onClick={() => navigate('/engagements/create')}
        >
          + Add New Engagement
        </button>
      </div>
      <div className="d-flex justify-content-between">
        <div>
          <label className="">Search</label>
          <div className="input-group mb-3 input-group-border">
            <input
              type="text"
              className="form-control border border-0 outline-0"
              placeholder="By any term"
              aria-label="By any term"
              aria-describedby="button-addon2"
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              onKeyDown={handleEnterKeyPress}
            />
            {isSearch ? (
              <span
                className="input-group-text border border-0 search"
                onClick={() => clearSearch()}
              >
                <FontAwesomeIcon icon={faClose} />
              </span>
            ) : (
              <span
                onClick={handleSearch}
                className="input-group-text border border-0 search"
              >
                <FontAwesomeIcon icon={faSearch} />
              </span>
            )}
          </div>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>
              Team Code Name
              <span className="ms-2"></span>
              <FontAwesomeIcon
                icon={
                  sortColumn === 'projectName' && sortOrder === 'asc'
                    ? faArrowUpAZ
                    : faArrowDownAZ
                }
                onClick={() => handleSort('projectName')}
              />
            </th>
            <th>
              Client
              <span className="ms-2"></span>
              <FontAwesomeIcon
                icon={
                  sortColumn === 'client.name' && sortOrder === 'asc'
                    ? faArrowUpAZ
                    : faArrowDownAZ
                }
                onClick={() => handleSort('client.name')}
              />
            </th>
            <th>
              Team
              <span className="ms-2"></span>
              <FontAwesomeIcon
                icon={
                  sortColumn === 'team.name' && sortOrder === 'asc'
                    ? faArrowUpAZ
                    : faArrowDownAZ
                }
                onClick={() => handleSort('team.name')}
              />
            </th>
            <th>
              Start Date
              <span className="ms-2"></span>
              <FontAwesomeIcon
                icon={
                  sortColumn === 'startDate' && sortOrder === 'asc'
                    ? faSortNumericDown
                    : faSortNumericUp
                }
                onClick={() => handleSort('startDate')}
              />
            </th>
            <th>
              End Date
              <span className="ms-2"></span>
              <FontAwesomeIcon
                icon={
                  sortColumn === 'endDate' && sortOrder === 'asc'
                    ? faSortNumericDown
                    : faSortNumericUp
                }
                onClick={() => handleSort('endDate')}
              />
            </th>
            <th>{/*Actions*/}</th>
          </tr>
        </thead>
        <tbody>
          {sortedEngagements.map((engagement, index) => {
            return (
              <tr
                className="pointer-cursor"
                onClick={() => navigate(`/engagements/${engagement.id}`)}
                key={index}
              >
                <td>{engagement.projectName}</td>
                <td>{engagement.client?.name || ''}</td>
                <td>{engagement.team?.name || ''}</td>
                <td>{moment(engagement.startDate).format('MM/DD/YYYY')}</td>
                <td>
                  {engagement.endDate
                    ? moment(engagement.endDate).format('MM/DD/YYYY')
                    : ''}
                </td>
                <td>
                  <FontAwesomeIcon icon={faPenToSquare} />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      {engagements.length === 0 && (
        <h2 className="text-center">No engagements found</h2>
      )}
    </div>
  )
}

export default EngagementsView
